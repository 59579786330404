import React from 'react';
import './App.css';

function App() {
  return (
    <div className="coming-soon">
      <div className="logo-container">
        <img src="/logo.png" alt="RDIYALI Logo" className="logo-image" />
        <h1 className="logo-text">RDIYALI</h1>
      </div>
      <footer>
        <div className="footer-bar">
          <p className="footer-text">&copy;2024 RDIYALI. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default App; 